class SearchInput {
    constructor(input, resultContainer, options = null) {
        this.input = $(input);
        this.resultContainer = $(resultContainer);
        this.options = {
            "searchOnInput": true,  // Whether to search while typing or just on enter
            "renderResultCallback": this.renderResult,
            "clickResultCallback": this.clickResult
        };
        if (options === null) {
            options = {};
        }
        this.options = Object.assign(this.options, options);

        this.searchCache = {};
        this.activeRequest = null;

        this.init();
    }

    init() {
        this.resultContainer.html("");
        this.input.val("");

        if (this.options.searchOnInput) {
            this.input.on("input", function(e) {
                this.search(this.input.val());
            }.bind(this));
        } else {
            this.input.on("keydown", function(e) {
                if (e.which == 13) {
                    this.search(this.input.val());
                    return false;
                }
            }.bind(this));
        }

        let that = this;
        this.resultContainer.on("mousedown", ".search-result", function(e) {
            if (e.which == 1) {                
                that.options.clickResultCallback($(this));
                return false;
            } else if (e.which == 2) {
                that.options.clickResultCallback($(this), "_blank");
                return false;
            }
        })
    }

    async search(value) {
        if (this.activeRequest !== null) {
            this.activeRequest.abort();
            this.activeRequest = null;
        }

        value = value.toLowerCase();
        if (value in this.searchCache) {
            // console.log(`Using cached results for ${value}`);
            this.showSearchResults(this.searchCache[value], value);
            return;
        }

        if (value.length < 4) {
            // console.log(`Not searching for "${value}" - too short`);
            this.showSearchResults([], value);            
            return;
        }
        // console.log(`Searching for "${value}"`);
        try {
            this.activeRequest = $.ajax({
                "url": "api/search/query",
                data: {
                    "q": value
                }
            });
            let results = await this.activeRequest;
            this.searchCache[value] = results.results;
            this.showSearchResults(results.results, value);
        } catch (e) {
            try {
                if (e.statusText !== "abort") {
                    console.error(e);
                }
            } catch (e2) {
                console.error(e);
                console.error(e2);
            }
            this.showSearchResults([], value);
        }
    }

    showSearchResults(results, value) {
        this.resultContainer.html("");        
        if (value.length === 0) {
            this.resultContainer.html(`
                <div class="center"><i>Suchen Sie auf unserer Webseite</i></div>
            `);
            return;
        } else if (results.length === 0) {            
            this.resultContainer.html(`
                <div class="center"><i>(Noch) keine Ergebnisse</i></div>
            `);
            return;
        }
        for (let result of results) {
            this.resultContainer.append(this.options.renderResultCallback(result));
        }
    }

    renderResult(result) {
        let icon = "";
        let timeInfo = "";
        if (result["fa-icon"].length > 0) {
            icon = `<i class="${result['fa-icon']}"></i>`;
        }
        if (result["resourceTypeId"] === "post") {
            let timestamp = result["resourceTimestamp"];
            timeInfo = `<span class="search-result-time">${moment(timestamp * 1000).format("DD.MM.YYYY")}</span>`;
        }
        return `
            <div class="search-result" data-link="${result.path}">
                <span class="search-result-header">
                    ${icon}
                    <span class="search-result-title">
                        ${result.title}
                    </span>
                </span>
                ${timeInfo}
                <span class="search-result-highlight">
                    ${result.highlight}
                </span>
            </div>
        `;
    }

    clickResult(result, target = "_self") {
        let link = result.data("link") ?? "";
        if (link !== "") {
            window.open(link, target).focus();
        }
    }
}